import { mapActions } from 'vuex'
import store from '../../../store'
import { myMixin } from '../../../mixins'

export default {
  mixins: [myMixin],
  data: () => ({
    collections: [],
    params: {},
    message: '',
    error: false,
    errors: {},
    loading: false,
    meta: {},
    itemDelete: {},
    model: {},
    show: false,
    showForm: false,
    text: 'Carregando dados...',
    loadingProgress: false,
    textDelete: '',
    deleting: false,
    
      headers: [
        { text: 'Campus', value: 'campus.name', sortable: false},
        { text: 'Curso', value: 'course.name', sortable: false},
        { text: 'Turno', value: 'shift.name', sortable: false},
        { text: 'Nome', value: 'name'},
        { text: 'Período', value: 'time_course'},
        { text: 'Categoria', value: 'category.name', sortable: false},
        { text: 'Status', value: 'status.name', sortable: false},
        { text: 'Ações', value: 'actions', sortable: false}
    ]
    }),
    
    watch: {
      params(params_new, params_old){
        if(params_new != params_old) {
            this.getData()
        }
      },
       //Função para tratar quando um item for exibido para deletar
      itemDelete(item_new, item_old){
          if(item_new !== item_old && Object.keys(item_new).length > 0)
          {
              this.show = true;
              this.textDelete = "Deseja excluir " + item_new.name + "?";
          } else {
              this.show = false
              this.textDelete = '';
          }
      }, //Função para limpar a propriedade do usuário que será excluído quando usuário clica no cancelar
      show(show_new, show_old) {
          
          if(show_new != show_old && !show_new) {
              this.itemDelete = {};
          }
      },

      model(item_new, item_old){
          
        if(item_new !== item_old && this.model.id)
          {
              this.showForm = true;
              this.errors = {};
          } else if(Object.keys(this.model).length === 0){
              this.showForm = false;
              this.errors = {};
          }           
      },

      showForm(show_new, show_old) {
          if(show_new != show_old && !show_new) {
              this.model = {};
          }
      },
    },
    components: {
      
    },
    mounted(){
       
    },
    created() {
      
    },
    methods: {
      ...mapActions('group', ['ActionDeleteGroup', 'ActionFindGroups']),
        
      getData(){
      
        this.error = false;
        this.message = '';
        const params = this.params;
        this.loadingProgress = true;
        this.showForm = false;

        Object.assign(params, {with: 'status,course,campus,shift,category,disciplines,baseGroups,resources,availabilities,subGroups'});

        this.ActionFindGroups(params)
            .then((res) => {
                this.collections = res.data;
                this.meta = res.meta;
            })
            .finally(() => {
                this.loading = false
                this.loadingProgress = false;
            })
    },

    deleteItem()
    {
        this.deleting = true;
        this.message = '';

        this.ActionDeleteGroup({id: this.itemDelete.id})
            .then((res) => {
                this.message = res.message;
                this.show = false;
                this.getData();
            })
            .catch((error) =>{
                this.message = error.message;
                this.error = true;
            })
            .finally(() => {
                this.deleting = false;
            });
    }

  },

}
